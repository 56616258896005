import * as Backend from 'queries/BackendRequest';

export interface EventStats {
  eventId: number;
  isFull: boolean;
}

const fetchEventStats = async (pid: string): Promise<EventStats[]> => {
  const result = await Backend.request<EventStats[]>('get', `/event/${pid}/stats`, {}, {});
  return result.data.data;
};

export const getEventStatsQuery = (pid: number | string) => ({
  queryKey: ['getEventStatsQuery', pid],
  queryFn: () => fetchEventStats(pid as string),
  enabled: !!pid,
});

export default getEventStatsQuery;
