import { CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import useEventFilter from 'components/eventFilter/useEventFilter';
import EventPageDescription from 'components/eventPageDescription/EventPageDescription';
import useIsMobile from 'hooks/useIsMobile';
import useProjectId from 'hooks/useProjectId';
import { getEventGroupsQuery } from 'queries/event/user-event-groups';
import { getEventStatsQuery } from 'queries/event/user-event-stats';
import { getEventsQuery } from 'queries/event/user-events';
import { getUserTicketsQuery } from 'queries/event/user-tickets';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserEvent } from 'types/types';
import { classifyEventList, filterEventList } from 'utils/eventFilter';
import useBookEvent from '../../queries/event/useBookEvent';
import Content from '/components/content/Content';
import StartEventDivider from '/components/eventCard/StartEventDivider';
import { EventStatus, TYPE, UserEventCard } from '/components/eventCard/UserEventCard';
import EventFilterController from '/components/eventFilter/EventFilterController';
import EventSearch from '/components/eventSearch/EventSearch';
import FabComponent from '/components/fab/Fab';
import Footer from '/components/footer/Footer';

const Events: React.FC = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const projectId = useProjectId();
  const { data: events } = useQuery(getEventsQuery(projectId));
  const { data: eventStats } = useQuery(getEventStatsQuery(projectId));
  const { data: eventGroups } = useQuery(getEventGroupsQuery(projectId));
  const { data: userTickets } = useQuery(getUserTicketsQuery(projectId));
  const eventFilter = useEventFilter();
  const bookEvent = useBookEvent();
  const bookedEventIds = userTickets ? userTickets.map((ticket) => ticket.eventId) : [];

  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  /**
   * Returns a list of events that has been filtered
   */
  const classified = useMemo(
    () =>
      classifyEventList(
        filterEventList(
          events || [],
          eventFilter.eventFilter,
          bookedEventIds,
          eventStats || [],
          eventGroups || [],
          eventFilter.search || ''
        )
      ),
    [eventFilter.eventFilter, eventFilter.search, events, bookedEventIds, eventStats, eventGroups]
  );

  /**
   * Book a ticket || redirect to ticket page
   */
  const handleBooking = (item: UserEvent) => {
    if (bookedEventIds.includes(item.eventId)) {
      navigate(`/${projectId}/tickets`);
    } else {
      bookEvent.mutate({
        projectId,
        eventId: item.eventId,
        eventTitle: item.displayTitle,
      });
    }
  };

  // returns a list of categories that can go into the event
  const listOfCategories = (eventId: number) => {
    if (!eventGroups) return [];

    const categoryNames: string[] = [];
    eventGroups.forEach((eventGroup) => {
      if (eventGroup.eventIDs.includes(eventId) && eventGroup.isBroadcasted) categoryNames.push(eventGroup.name);
    });

    return categoryNames;
  };

  const getStatus = (eventId: number, item: UserEvent): EventStatus => {
    if (bookedEventIds.includes(eventId)) {
      return TYPE.BOOKED as EventStatus;
    }
    if (
      new Date().getTime() < Date.parse(item.bookingStartDatetime) ||
      Date.parse(item.bookingEndDatetime) < new Date().getTime()
    ) {
      return TYPE.CLOSED as EventStatus;
    }
    if (eventStats?.find((d) => d.eventId === eventId)?.isFull) {
      return TYPE.FULL as EventStatus;
    }
    return TYPE.BOOKABLE as EventStatus;
  };

  if (!events || !eventStats || !eventGroups || !userTickets) return <CircularProgress style={{ padding: '8px' }} />;

  return (
    <>
      <Content
        header={<EventPageDescription />}
        sidebarComponent={
          <EventFilterController
            eventFilter={eventFilter}
            events={events}
            tickets={userTickets}
            eventGroups={eventGroups}
            filterOverlay={isOverlayOpen}
            closeOverlay={() => setIsOverlayOpen(false)}
          />
        }
      >
        <EventSearch openOverlay={() => setIsOverlayOpen(true)} setEventSearch={eventFilter.setSearch} />
        <div>
          {classified.map((classifiedItem) => {
            const item = classifiedItem.data;
            return (
              <div key={`event--${item.eventId}`}>
                {classifiedItem.first && <StartEventDivider date={item.startDatetime} />}
                <UserEventCard
                  title={item.displayTitle}
                  isMobile={isMobile}
                  state={getStatus(item.eventId, item)}
                  location={item.location}
                  attendance={item.attendanceLimit}
                  price={item.basePriceSek}
                  startTime={item.startDatetime}
                  endTime={item.endDatetime}
                  description={item.description}
                  categories={listOfCategories(item.eventId)}
                  booking={bookEvent.isPending}
                  handleButton={() => handleBooking(item)}
                  transferEndTime={new Date()}
                  handleTransfer={() => null}
                  buttonDisabled={false}
                />
              </div>
            );
          })}
        </div>
      </Content>
      <FabComponent />
      <Footer />
    </>
  );
};

export default Events;
