import { useMutation, useQueryClient } from '@tanstack/react-query';
import useOpenFeedback from 'hooks/useOpenFeedback';
import * as Backend from 'queries/BackendRequest';
import { ErrorResponse } from 'types/types';

interface MutationProps {
  projectId: number | string;
  eventId: number;
  eventTitle: string;
}

const useBookEvent = () => {
  const feedback = useOpenFeedback();
  const queryClient = useQueryClient();

  const bookEvent = useMutation({
    mutationFn: async ({ eventId }: MutationProps) => {
      await Backend.request('post', `/user/booking/${eventId}`);
    },
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({ queryKey: ['getUserTicketsQuery', variables.projectId] });
      feedback.openSuccess(`Success: A ticket for ${variables.eventTitle} has been booked!`);
    },
    onError: async (error: ErrorResponse, variables) => {
      await queryClient.invalidateQueries({ queryKey: ['getUserTicketsQuery', variables.projectId] });
      const errorMessage = error?.response?.data?.error?.message;
      if (errorMessage === 'User is not allowed to book event due to a filter or group') {
        feedback.openError(`Error: You have booked too many events of that category`);
        return;
      }
      feedback.openError(`Error: Failed to book a ticket for ${variables.eventTitle} (${errorMessage})`);
    },
  });

  return bookEvent;
};

export default useBookEvent;
