import React, { useEffect, useState } from 'react';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { styled } from '@mui/material';
import Fab from '@mui/material/Fab';

const StyledFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: '32px',
  right: '128px',
  zIndex: '100',
  [theme.breakpoints.down('md')]: {
    right: '32px',
  },
}));

const FabComponent: React.FC = () => {
  const [showUpButton, setShowUpButton] = useState(false);

  const handleScroll = () => {
    setShowUpButton(window.scrollY > window.screen.availHeight);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      {showUpButton && (
        <StyledFab color="secondary" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
          <ArrowUpwardIcon />
        </StyledFab>
      )}
    </>
  );
};

export default FabComponent;
