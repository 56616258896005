import React from 'react';
import Emojify from 'components/emojify/Emojify';
import ReactMarkdown from 'react-markdown';
import 'property-information';
import { useQuery } from '@tanstack/react-query';
import useProjectId from 'hooks/useProjectId';
import getPrivateText from 'queries/texts/get-private-text';

const EventPageDescription = () => {
  const projectId = useProjectId();
  const { data } = useQuery(getPrivateText(projectId));

  return (
    <Emojify>
      <ReactMarkdown>{data?.EVENTS_MAIN ?? ''}</ReactMarkdown>
    </Emojify>
  );
};

export default EventPageDescription;
